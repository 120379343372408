<template>
  <div>
    <v-card flat elevation="7">
      <v-row class="px-6 py-3">
        <v-col cols="12" sm="10">
          <div>
            <div class="inf-font customer-header" v-text="getHeaderPhrase() || '-----'"></div>
          </div>
        </v-col>
        <v-col cols="12" sm="2" class="justify-end">
          <v-row class="justify-end">
            <v-dialog v-model="cancelConfirmDialog" max-width="290"
              v-if="getOrderStatus(getOrder().orderStatusId).code !== 'CANCELED'">
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom v-if="editModeStatus === false">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn elevation="4" fab large class="float-right mx-2" color="error" v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }">
                      <v-icon x-large>mdi-close-octagon</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('deleteParam', {param:$t('order')}) }}</span>
                </v-tooltip>
              </template>
              <v-card>
                <v-card-title class="text-h5 error" style="color: #ffffff;">
                  {{ $t('deleteParam', {param:$t('order')}) }}
                </v-card-title>
                <v-card-text>
                  <span class="ma-3">
                    {{ $t('deleteText', {param: $t('order')}) }}
                  </span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="gray darken-1" depressed @click="cancelConfirmDialog = false" v-t="'close'"></v-btn>
                  <v-btn color="error" depressed :loading="isCancelingOrder" :disabled="isCancelingOrder" @click="cancelOrder" v-t="'confirm'"></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-tooltip bottom v-show="editModeStatus" v-if="getOrderStatus(getOrder().orderStatusId).code !== 'CANCELED'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="4" fab large class="float-right" outlined @click="editMode" v-bind="attrs" v-on="on">
                  <v-icon v-if="editModeStatus">
                    mdi-lock-open
                  </v-icon>
                  <v-icon v-else>
                    mdi-lock
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="editModeStatus">Save changes</span>
              <span v-else>Edit order</span>
            </v-tooltip>

            <v-menu bottom left offset-y v-if="isInRole(['CommercialSpecialist'])">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="4" fab large class="float-right mx-2" outlined v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="(getOrderStatus(getOrder().orderStatusId).code === 'WIP' || getOrderStatus(getOrder().orderStatusId).code === 'INS' || getOrderStatus(getOrder().orderStatusId).code === 'CANCELED') && isInRole(['CommercialSpecialist'])">
                  <v-list-item-title>
                    <v-btn :color="'primary'" plain @click="changeOrderStatus('TRANSMITTED')">
                      {{ $t('revertOrderStatusTo') }}&nbsp;
                      <v-chip :color="'transmitted'" text-color="white"
                        style="font-weight: 600; font-size: 0.875rem; margin-left: 6px;">
                        <v-icon left>mdi-progress-wrench</v-icon>
                        {{  $t('transmitted')  }}
                      </v-chip>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="getOrderStatus(getOrder().orderStatusId).code === 'PROCESSED' && isInRole(['CommercialSpecialist'])">
                  <v-list-item-title>
                    <v-btn :color="'primary'" plain @click="changeOrderStatus('WIP')">
                      {{ $t('revertOrderStatusTo') }}&nbsp;
                      <v-chip :color="'wip'" text-color="white"
                        style="font-weight: 600; font-size: 0.875rem; margin-left: 6px;">
                        <v-icon left>mdi-progress-clock</v-icon>
                        {{ $t('workInProgress') }}
                      </v-chip>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn :color="'primary'" plain @click="forceRecalculationOfOrderTotal()" v-t="'forceSync'"></v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn :color="'primary'" plain @click="massiveDuplicate()" v-t="'massiveDuplicate'"></v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-dialog v-model="dialogCut" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="4" fab large class="float-right" outlined color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-content-cut</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5" v-t="'cutOrderRows'"></v-card-title>
                <v-card-text>

                  <div>
                    <v-data-table dense :headers="headersCut" :items="orderRows" :sort-desc="[true]">
                      <template v-slot:item.qty="props">
                        <v-edit-dialog :return-value.sync="props.item.qty">
                          <v-icon color="red" v-if="props.item.qty > props.item.quantity || props.item.qty < 0">mdi-alert</v-icon>
                          {{ props.item.qty }}
                          <template v-slot:input>
                            <v-text-field v-model="props.item.qty" :label="$t('edit')" single-line type="number" @change="applyQty(props, props.item.qty)"></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:item.copy="props">
                        <v-icon class="float-right" color="primary" @click="applyQty(props, props.item.quantity)">
                          mdi-arrow-right-bold-circle-outline
                        </v-icon>
                      </template>
                    </v-data-table>
                  </div>

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialogCut = false" v-t="'close'"></v-btn>
                  <v-btn color="green darken-1" text @click="handleCloningOrderWithPartialRows()" v-t="'confirm'"></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-row>
        </v-col>
      </v-row>
      <v-row class="px-6 py-0 justify-center">

        <!-- v-if="(getOrderStatus(getOrder().orderStatusId).code === 'PENDING-AUTH') " && imSuperUserPremiumPlus() -->
        <v-alert prominent dense icon="mdi-progress-star" type="warning"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'PENDING-AUTH' && imSuperUserPremiumPlus">
          <v-row align="center">
            <v-row align="center">
              <v-col class="grow">
                {{ $t('orderWaiting') }} <span style="font-weight: 600;">"{{ $t('authorization') }}"</span>.
              </v-col>
              <v-col class="shrink">
                <v-btn color="wip" :dark="false" :disabled="editModeStatus !== false" @click="changeOrderStatus('WIP')">
                  <div style="display: flex; flex-direction: column;">
                    <span v-t="'authorizeTo'"></span>
                    <span style="font-weight: 600;" v-t="'workInProgress'"></span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-progress-alert" type="warning"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'PENDING-AUTH' && !imSuperUserPremiumPlus">
          <v-row align="center">
            <v-row align="center">
              <v-col class="grow">
                {{  $t('orderWaiting')  }} <span style="font-weight: 600;">"{{ $t('authorization') }}"</span>.
              </v-col>
              <!-- <v-col class="shrink">
              <v-btn color="wip" :dark="false" :disabled="editModeStatus !== false"
                     @click="changeOrderStatus('WIP')">
                <div style="display: flex; flex-direction: column;"><span>AUTHORIZE TO</span><span
                    style="font-weight: 600;">WORK IN PROGRESS</span></div>
              </v-btn>
            </v-col> -->
            </v-row>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-progress-wrench" type="info" color="grey lighten-2"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'TRANSMITTED' && !isInRole(['CommercialSpecialist'])">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('orderStatusText') }} <span style="font-weight: 600;">"{{ $t('transmitted') }}"</span>.
            </v-col>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-progress-wrench" type="info" color="grey lighten-2"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'TRANSMITTED' && isInRole(['CommercialSpecialist'])">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('orderStatusText') }} <span style="font-weight: 600;">"{{ $t('transmitted') }}"</span>. 
              {{ $t('whenReady') }}
            </v-col>
            <v-col class="shrink">
              <v-btn color="wip" :dark="false" :disabled="editModeStatus !== false"
                @click="checkIfAdministrativeNotesForCustomers()">
                <div style="display: flex; flex-direction: column;">
                  <span v-t="'changeStatus'"></span>
                  <span style="font-weight: 600;" v-t="'workInProgress'"></span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-dialog v-model="showNotes" max-width="50vw">
          <v-card>
            <v-card-title class="text-h3" v-t="'reviewNotes'"></v-card-title>

            <v-card-text v-if="notesToShow.customerBusinessName !== null">
              <v-row>
                <v-col cols="12" sm="12" class="pa-1">
                  <h4>{{ this.notesToShow.customerBusinessName }} {{ $t('notes') }} ({{ $t('customer') }})</h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mt-1 inf-font pa-4" v-text="notesToShow.customerNotes"></v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mt-1 inf-font pa-4" v-text="notesToShow.customerAdministrativeNotes"></v-card>
                </v-col>
                <v-col cols="12" sm="12" class="pa-0">
                  <v-alert prominent dense icon="mdi-progress-wrench" type="error"
                    v-if="this.notesToShow.customerHasPaymentProblems">
                    <v-row align="center">
                      <v-col class="grow">
                        <span style="font-weight: 600;" v-t="'customerPaymentProblem'"></span>.
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="notesToShow.invoicingBusinessName !== null">
              <v-row>
                <v-col cols="12" sm="12">
                  <h4>{{ this.notesToShow.invoicingBusinessName }} {{ $t('notes') }} ({{ $t('invoicingCustomer', {r:''}) }})</h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mt-1 inf-font pa-4" v-text="notesToShow.invoicingNotes"></v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mt-1 inf-font pa-4" v-text="notesToShow.invoicingAdministrativeNotes"></v-card>
                </v-col>
                <v-col cols="12" sm="12" class="pa-0">
                  <v-alert prominent dense icon="mdi-progress-wrench" type="error"
                    v-if="this.notesToShow.invoicingHasPaymentProblems">
                    <v-row align="center">
                      <v-col class="grow">
                        <span style="font-weight: 600;" v-t="'customerPaymentProblem'"></span>.
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="notesToShow.shippingBusinessName !== null">
              <v-row>
                <v-col cols="12" sm="12">
                  <h4>{{ this.notesToShow.shippingBusinessName }} {{ $t('notes') }} ({{ $t('shippingCustomer') }})</h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mt-1 inf-font pa-4" v-text="notesToShow.shippingNotes"></v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mt-1 inf-font pa-4" v-text="notesToShow.shippingAdministrativeNotes"></v-card>
                </v-col>
              </v-row>
              <v-col cols="12" sm="12" class="pa-0">
                <v-alert prominent dense icon="mdi-progress-wrench" type="error"
                  v-if="this.notesToShow.shippingHasPaymentProblems">
                  <v-row align="center">
                    <v-col class="grow">
                      <span style="font-weight: 600;" v-t="'customerPaymentProblem'"></span>.
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="grey" text @click="showNotes = false" v-t="'cancel'"></v-btn>

              <v-btn color="wip" :dark="false" @click="changeOrderStatus('WIP')">
                <div style="display: flex; flex-direction: column; color: #fff;">
                  <span v-t="'changeStatus'"></span>
                  <span style="font-weight: 600; color: #fff" v-t="'workInProgress'"></span></div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-alert prominent dense icon="mdi-progress-wrench" type="warning"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'WIP' && isInRole(['CommercialSpecialist'])">
          <v-row align="center">
            <v-col class="grow">{{ $t('orderStatusText') }} <span style="font-weight: 600;">"{{ $t('workInProgress') }}"</span>.</v-col>
            <v-col class="shrink">
              <v-btn color="success" :dark="false" :disabled="editModeStatus !== false"
                @click="changeOrderStatus('PROCESSED')">
                <div style="display: flex; flex-direction: column;">
                  <span v-t="'changeStatus'"></span>
                  <span style="font-weight: 600;" v-t="'processed'"></span>
                </div>
              </v-btn>
              <v-btn color="info" :dark="false" :disabled="editModeStatus !== false" @click="changeOrderStatus('INS')">
                <div style="display: flex; flex-direction: column;">
                  <span v-t="'changeStatus'"></span>
                  <span style="font-weight: 600;" v-t="'ins'"></span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-progress-clock" type="warning"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'WIP' && !isInRole(['CommercialSpecialist'])">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('orderIs') }} <span style="font-weight: 600;">"{{ $t('workInProgress') }}"</span>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-progress-check" type="success"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'PROCESSED'">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('orderHasBeen')}} <span style="font-weight: 600;">"{{ $t('processed') }}"</span>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-progress-download" type="info"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'INS'">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('orderHasBeen') }} <span style="font-weight: 600;">"{{ $t('insExt') }}"</span>
            </v-col>
            <v-col class="shrink">
              <v-btn color="success" :dark="false" :disabled="editModeStatus !== false"
                @click="changeOrderStatus('PROCESSED')">
                <div style="display: flex; flex-direction: column;">
                  <span>{{ $t('changeStatus') }}</span>
                  <span style="font-weight: 600;">{{  $t('processed')  }}</span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert prominent dense icon="mdi-close-octagon" type="error"
          v-if="getOrderStatus(getOrder().orderStatusId).code === 'CANCELED'">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('orderHasBeen')}} <span style="font-weight: 600;">"{{ $t('canceled') }}"</span>
            </v-col>
          </v-row>
        </v-alert>
      </v-row>
      <v-tabs vertical v-if="editModeStatus">
        <v-tab right>
          <v-icon left>mdi-page-layout-header</v-icon>
          {{ $t('header') }}
        </v-tab>
        <v-tab right>
          <v-icon left>mdi-page-layout-footer</v-icon>
          {{ $t('footer') }}
        </v-tab>
        <v-tab-item :transition="false">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-autocomplete v-model="getOrder().subagentId" :items="agentsDropdown" :label="$t('subAgent')" :disabled="true" required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-autocomplete v-model="getOrder().agentId" :items="agentsDropdown" :label="$t('agent')" :disabled="true"
                  required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-autocomplete v-model="getOrder().familyId" :items="familiesDropdown" :label="$t('brand')" :disabled="true"
                  required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-autocomplete v-model="getOrder().customerId" :items="customersDropdown" :disabled="true"
                  @change="onCustomerUpdate" :label="$t('customerCode')"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-autocomplete v-model="getOrder().customerLocationId" :items="customerLocationsDropdown"
                  :disabled="true" :label="$t('location')"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-switch v-model="getOrder().hasDifferentInvoicingCustomer" :disabled="true" :label="$t('invoiceToDifferentCustomer')" />
              </v-col>
              <v-col cols="12" sm="3" v-show="getOrder().hasDifferentInvoicingCustomer">
                <v-autocomplete v-model="getOrder().invoicingCustomerId" :items="customersDropdown"
                  @change="onInvoicingCustomerUpdate" :label="$t('invoicingCustomer', {r:''})"></v-autocomplete>
                <v-autocomplete v-model="getOrder().invoicingCustomerLocationId"
                  :items="invoicingCustomerLocationsDropdown" :label="$t('invoicingCustomerLocation')"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" @click="updateHeader">
                <v-switch v-model="getOrder().hasDifferentShippingCustomer" :disabled="true"
                  :label="$t('shipToDifferentCustomer')" />
              </v-col>
              <v-col cols="12" sm="3" v-show="getOrder().hasDifferentShippingCustomer">
                <v-autocomplete v-model="getOrder().shippingCustomerId" :items="customersDropdown"
                  @change="onShippingCustomerUpdate" :label="$t('shippingCustomer')"></v-autocomplete>
                <v-autocomplete v-model="getOrder().shippingCustomerLocationId" :items="shippingCustomerLocationsDropdown"
                  :label="$t('shippingCustomerLocation')"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-menu v-model="menu" :close-on-content-click="true" :nudge-right="40" min-width="auto" offset-y
                  transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="orderDateFormatted" v-bind="attrs" v-on="on" :label="$t('documentDate')"
                      prepend-icon="mdi-calendar" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="internalOrderDate" @change="applyNewOrderDate()"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete v-model="getOrder().orderTypeId" :items="orderTypesDropdown"
                  :label="$t('orderType')"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete v-model="getOrder().priceCatalogId" :items="priceCatalogDropdown"
                  @change="onPriceCatalogUpdate" :label="$t('priceCatalog')"></v-autocomplete>
              </v-col>
              <v-dialog v-model="showUpdatePriceDialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5 warning" style="color: #ffffff;" v-t="'changePriceCatalog'"></v-card-title>
                  <v-card-text>
                    <span class="ma-3">{{ $t('updateText', {param: $t('priceCatalog')}) }}</span>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" depressed @click="restorePriceUpdate" v-t="'cancel'"></v-btn>
                    <v-btn color="warning" depressed @click="forcePriceUpdate" v-t="'confirm'"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-col cols="12" sm="3">
                <v-text-field v-model="getOrder().customerPurchaseOrder" :counter="50" :rules="poRules" class="pr-2"
                  hide-details="auto" :label="$t('po')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-textarea v-model="getOrder().notes" :counter="255" :rules="noteRules" :label="$t('note')"
                  prepend-icon="mdi-comment" rows="3" solo></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-textarea v-model="getOrder().privateNotes" :counter="255" :rules="noteRules" :label="$t('internalNotes')"
                  prepend-icon="mdi-comment" rows="3" solo></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

        </v-tab-item>

        <v-tab-item :transition="false">

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <label class="font-weight-bold" v-t="'paymentInformations'"></label>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-autocomplete v-model="getOrder().paymentTermId" :items="paymentTermsDropdown" :label="$t('payment')"
                      @change="selectedPaymentTerm"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="getOrder().documentCurCollectionCharges" hide-details="auto"
                      :label="$t('collectionExpenses')" min="0" type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-autocomplete v-model="getOrder().paymentMethodId" :items="paymentMethodsDropdown"
                      :label="$t('paymentMethod', {r:''})" @change="selectedPaymentMethod"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-autocomplete v-model="getOrder().customerIbanId" :items="customerIbanDropdown"
                      :label="$t('iban', {r:''})"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-menu v-model="menu2" :close-on-content-click="true" :nudge-right="40" min-width="auto" offset-y
                      transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="estimatedDeliveryDateFormatted" v-bind="attrs" v-on="on"
                          :label="$t('estimatedDeliveryDate')" prepend-icon="mdi-calendar" readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="internalEstimatedDeliveryDate" @change="applyNewOrderDeliveryDate()"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <label class="font-weight-bold" v-t="'deadlines'"></label>
                <v-data-table :headers="deadlines.headers" :items="paymentTermDeadlinesTable" :items-per-page="5"
                  class="elevation-1 mt-5" dense>
                  <template v-slot:item.expirationDate="{ item }">
                    {{ item.expirationDate | formatDate }}
                  </template>
                  <template v-slot:item.amount="{ item }">
                    {{ switchProp(item, "Amount") | tenantCurrency(false, selectedCurrency.symbol) }}
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete v-model="getOrder().shipmentType" :items="packageTypes"
                  :label="$t('packagingType')"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-show="getOrder().shipmentType === 'PALLET'" :label="$t('palletHeightCm')"
                  v-model="getOrder().palletHeight" min="0" type="number"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

        </v-tab-item>
      </v-tabs>
      <!-- VIEW MODE  -->
      <v-col cols="12" v-else>
        <v-row>
          <v-col cols="12" sm="4" class="pl-2">
            <div>
              <label v-t="'customer'"></label>
              <div class="inf-font" v-text="getCustomerName(getOrder().customerId)"></div>
              <div class="inf-font" v-html="getCustomerLocationName('customer', getOrder().customerLocationId)"></div>
              <div v-if="getOrder().hasDifferentInvoicingCustomer" class="pt-2">
                <label>{{ $t('invoicingCustomer', {r:''}) }}</label>
                <div class="inf-font" v-text="getCustomerName(getOrder().invoicingCustomerId)"></div>
                <div class="inf-font"
                  v-html="getCustomerLocationName('invoicing', getOrder().invoicingCustomerLocationId)"></div>
              </div>
              <div v-if="getOrder().hasDifferentShippingCustomer" class="pt-2">
                <label v-t="'shippingCustomer'"></label>
                <div class="inf-font" v-text="getCustomerName(getOrder().shippingCustomerId)"></div>
                <div class="inf-font" v-html="getCustomerLocationName('shipping', getOrder().shippingCustomerLocationId)">
                </div>
              </div>
              <div class=" mt-5 text-center d-flex float-left" v-if="!!applyTaxesFromTaxJar">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="applyTaxes">
                      {{ $t('applyParam', {param: $t('taxes')}) }}
                    </v-btn>
                  </template>
                  <span v-t="'applyTaxJar'"></span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div>
              <label v-t="'ownership'"></label>
              <div class="inf-font" v-text="getAgentName($t('subAgent'), getOrder().subagentId)"></div>
              <div class="inf-font" v-text="getAgentName($t('agent'), getOrder().agentId)"></div>
              <div class="inf-font" v-text="getAgentName($t('supervisorAgent'), getOrder().supervisorAgentId)"></div>
              <div class="inf-font" v-text="getAgentName($t('chiefAgent'), getOrder().chiefAgentId)"></div>
              <div class="inf-font" v-text="getCommercialSpecialistName($t('commercialSpecialist'), getOrder().commercialSpecialistId)"></div>
              <div class="inf-font" v-text="getPO($t('po'), getOrder().customerPurchaseOrder)"></div>
              <div class="inf-font mt-2" v-text="getPriceCatalogName($t('priceCatalog'), getOrder().priceCatalogId)"></div>
              <div class="inf-font mt-2" v-text="getPaymentTypeName($t('payment'), getOrder().paymentTermId)"></div>
              <div class="inf-font mt-2" v-text="getPaymentMethodName($t('paymentMethod', {r:''}), getOrder().paymentMethodId)">
              </div>
              <div class="inf-font mt-2" v-if="getOrder().customerIbanId"
                v-text="getCustomerIban('On', getOrder().customerIbanId)"></div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="pt-2">
              <div class="inf-font bold"
                v-text="getEstimatedDate(getOrder().estimatedDeliveryDate, getOrder().mandatory)"></div>
              <div class="inf-font" v-text="getPackageType(getOrder().shipmentType, getOrder().palletHeight)"></div>
              <div class="pa-0">
                <label v-t="'deadlines'"></label>
                <v-data-table :headers="deadlines.headers" :items="paymentTermDeadlinesTable" :items-per-page="5"
                  class="elevation-1 mt-5" dense>
                  <template v-slot:item.expirationDate="{ item }">
                    {{ item.expirationDate | formatDate }}
                  </template>
                  <template v-slot:item.amount="{ item }">
                    {{ item.amount }}
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5"><!--  v-if="getOrder().notes" -->
            <div class="mt-3">
              <label v-t="'notes'"></label>
              <v-card class="mt-5 inf-font pa-4" v-text="getOrder().notes"></v-card>
            </div>
          </v-col>
          <v-col cols="12" md="5"><!--  v-if="getOrder().privateNotes" -->
            <div class="mt-3">
              <label v-t="'internalNotes'"></label>
              <v-card class="mt-5 inf-font pa-4" v-text="getOrder().privateNotes"></v-card>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <v-chip class="mt-10 float-right" color="primary" :outlined="curr.name !== selectedCurrency.name"
              v-bind:key="12345 + i" v-for="(curr, i) in currentCurrencies" @click="showPrice(curr)">
              <v-icon left class="mr-4 ml-1">
                {{ `mdi-currency-${curr.name.toLowerCase()}` }}
              </v-icon>
              {{ curr.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <v-col cols="12" sm="12">
          <!--          <v-card-text v-if="!minOrderReached()">-->
          <!--            <v-alert border="bottom" colored-border type="warning" elevation="7">-->
          <!--              Minimum order ({{ getCustomer().minOrder | currency }}) not reached-->
          <!--            </v-alert>-->
          <!--          </v-card-text>-->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <!-- <v-switch
                    v-model="updateRowsSlide"
                    label="Slide to update rows"
                    color="red"
                    hide-details
                ></v-switch> -->
              </v-col>
              <v-col cols="12" sm="12" v-if="!updateRowsSlide">
                <v-autocomplete v-model="modelLang.langTag" :items="languagesDropdown" :label="$t('translation')" required
                  @change="translateItemDescription()"></v-autocomplete>
                <v-data-table :headers="readonlyRowsColumns" :items="orderRows" :items-per-page="500" dense
                  class="elevation-1">
                  <template v-slot:item.documentCurRowOriginalPrice="{ item }">
                    {{ switchProp(item, "OriginalPrice", true) | tenantCurrency(false, selectedCurrency.symbol) }}
                  </template>
                  <template v-slot:item.documentCurRowAmountAfterDiscount="{ item }">
                    {{ switchProp(item, "AmountAfterDiscount", true) | tenantCurrency(false, selectedCurrency.symbol) }}
                  </template>
                  <template v-slot:item.accountingCurRowOriginalPrice="{ item }">
                    {{ switchProp(item, "OriginalPrice", true) | tenantCurrency(false, selectedCurrency.symbol) }}
                  </template>
                  <template v-slot:item.accountingCurRowAmountAfterDiscount="{ item }">
                    {{ switchProp(item, "AmountAfterDiscount", true) | tenantCurrency(false, selectedCurrency.symbol) }}
                  </template>
                  <template v-slot:item.vatPercentageId="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ ((vatPercentageById(item.vatPercentageId)?.percentage ?? 0) /
                          100) | numeral('0.00 %') }}</span>
                      </template>
                      <span>{{ vatPercentageById(item.vatPercentageId)?.code ?? 'n.a.' }} - {{
                        vatPercentageById(item.vatPercentageId)?.description ?? 'n.a.' }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.isGift="{ item }">
                    <BooleanIcon :booleanValue="item.isGift" />
                  </template>
                  <template v-slot:item.isLoanForUse="{ item }">
                    <BooleanIcon :booleanValue="item.isLoanForUse" />
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <td></td>
                      <td></td>
                      <td class="text-right"><b>{{  $t('total')  }}:</b></td>
                      <td>{{ totalQuantity }}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-right"><b>{{ switchProp(getOrder(), "sTotal", true) | tenantCurrency(false, selectedCurrency.symbol) }}</b></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" sm="12" v-else>
                <div style="display: flex; justify-content: center;" v-if="!!showTotalError">
                  <v-alert dense outlined type="warning" v-t="'errorNewOrder'"></v-alert>
                </div>
                <TelerikGrid :collection="orderRows" :collapseKits="true" :familyId="order.familyId"
                  @updatedCollection="saveRows" :vatPercentageId="getCustomer()?.defaultVatRate"></TelerikGrid>
                <v-dialog transition="dialog-bottom-transition" v-model="dialogCSV"
                  :width="!csv.content.length ? 500 : 900">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block tile v-bind="attrs" v-on="on">Upload CSV</v-btn>
                    <!-- <v-btn color="primary">Load CSV</v-btn> -->
                  </template>
                  <v-card v-if="!csv.content.length">
                    <v-card-title class="headline grey lighten-2" v-t="'uploadCsv'"></v-card-title>
                    <v-card-text class="mt-3">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select v-model="csv.separator" :items="separators" :label="$t('separator')" outlined></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-file-input outlined v-model="csv.file" :label="$t('fileInput')" filled
                            @change="readContent"></v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeDialogCsv" v-t="'close'"></v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-card v-if="!!csv.content.length">
                    <v-card-text class="vh60-scrollable">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" v-for="col in getColumns(csv)">{{ col.text }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="el, i in csv.content" :key="i">
                              <td v-for="col in getColumns(csv)">{{ el[col.text] }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                    <v-card-text v-if="!!csv.export">
                      <v-row>
                        <v-col cols="12" :sm="getColumns(csv).length > 2 ? 4 : 6">
                          <v-select v-model="csv.code" :items="columns" :label="$t('code')" clearable outlined></v-select>
                        </v-col>
                        <v-col cols="12" :sm="getColumns(csv).length > 2 ? 4 : 6">
                          <v-select v-model="csv.quantity" :items="columns" :label="$t('quantity')" clearable
                            outlined></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="getColumns(csv).length > 2">
                          <v-select v-model="csv.discount" :items="columns" :label="$t('discount')" clearable
                            outlined></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeDialogCsv" v-t="'close'"></v-btn>
                      <v-btn color="primary" text @click="selectColumns" v-t="'preview'"></v-btn>
                      <v-btn color="primary" text @click="confirm" v-if="csv.export" v-t="'upload'"></v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" offset-sm="8" sm="4" class="pa-0 ma-0 align-center" style="display: flex;"
                v-if="!!updateRowsSlide">
                <v-chip class="mr-2">
                  {{ $t('quantity') }}:
                  {{ +getFooter().totalQuantity }}
                </v-chip>
                <v-chip class="mr-2">
                  {{ $t('beforeDiscount') }}:
                  {{ +getFooter().rowsAmountsBeforeDiscount | tenantCurrency(false, selectedCurrency.symbol) }}
                </v-chip>
                <v-chip class="mr-2">
                  {{ +getFooter().rowsDiscountAmount > 0 ? $t('surcharge') : $t('discount') }}
                  {{ +getFooter().rowsDiscountAmount | tenantCurrency(false, selectedCurrency.symbol) }}
                </v-chip>
                <v-chip class="mr-2">
                  {{ $t('total') }}:
                  {{ (+getFooter().documentCurRowsTotal) }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row class="my-5">
              <v-col cols="12" sm="12">
                <label class="font-weight-bold" v-t="'summary'"></label>
                <div class="text-center d-flex float-right" v-if="!!applyTaxesFromTaxJar">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="applyTaxes" v-t="'applyTaxes'"></v-btn>
                    </template>
                    <span v-t="'applyTaxJar'"></span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>


            <v-row class="mx-1 mb-5" v-if="!editModeStatus">
              <v-col cols="12" sm="7">
                <v-row class="castelletto-top castelletto-left">
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                    <span class="mini-label-castelletto" v-t="'discP'"></span><br />
                    <p class="float-right">{{ $numerals(getOrder().orderDiscountPercentage / 100, '0.00 %') }}</p>
                  </v-col>
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                    <span class="mini-label-castelletto" v-t="'discountAmount'"></span><br />
                    <p class="float-right">{{ switchProp(getOrder(), "OrderDiscountAbsolute") | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                  </v-col>
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                    <span class="mini-label-castelletto" v-t="'deliveryCharges'"></span><br />
                    <p class="float-right">{{ switchProp(getOrder(), "DeliveryCharges") | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                  </v-col>
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                    <span class="mini-label-castelletto" v-t="'collectionExpenses'"></span><br />
                    <p class="float-right">{{ switchProp(getOrder(), "CollectionCharges") | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                  </v-col>
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                    <span class="mini-label-castelletto" v-t="'stampDuty'"></span><br />
                    <p class="float-right">{{ 0 | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                  </v-col>
                </v-row>
                <v-row class="castelletto-bottom castelletto-left">
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="4">
                    <span class="mini-label-castelletto" v-t="'vatCode'"></span><br />
                    <div style="display: flex; flex-direction: row; justify-content: space-between;"
                      v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                      <span>{{ vatPercentageById(el.vatPercentageId)?.description }}</span>
                      <span>{{ vatPercentageById(el.vatPercentageId)?.percentage ?? 0 }} %</span>
                    </div>
                  </v-col>
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="5">
                    <span class="mini-label-castelletto" v-t="'vatAssessment'"></span><br />
                    <div style="display: flex; flex-direction: row; justify-content: space-between;"
                      v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                      <span>{{ el.qty }} {{ `Item${el.qty > 1 ? `s` : ``}` }}</span>
                      <span>{{ el.documentCurRowTaxable | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                    </div>
                  </v-col>
                  <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                    <span class="mini-label-castelletto" v-t="'tax'"></span><br />
                    <div style="display: flex; flex-direction: row; justify-content: space-between;"
                      v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                      <span>&nbsp;</span>
                      <span>{{ el.documentCurRowTaxes | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="castelletto castelletto-top castelletto-bottom cstelletto-left mt-0 mb-0" cols="12" sm="1">
                <span class="mini-label-castelletto" v-t="'currency'"></span>
                <p>{{ currencySymbol }}</p>
              </v-col>
              <v-col class="castelletto castelletto-top castelletto-bottom castelletto-right mt-0 mb-0" cols="12" sm="4">
                <v-row>
                  <v-col cols="12" sm="12" class="align-right">
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span v-t="'rowsTotalAfterDiscSurc'"></span>
                        </v-tooltip>
                        {{ $t('subTotal') }}:
                      </span>
                      <span>{{ switchProp(getOrder(), "Subtotal") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" class="align-right">
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span v-t="'subtotalDeliveryCollection'"></span>
                        </v-tooltip>
                        {{ $t('taxable') }}:
                      </span>
                      <span>{{ switchProp(getOrder(), "Taxable") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" class="align-right">
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span v-t="'taxes'"></span>
                        </v-tooltip>
                        {{ $t('taxes') }}:
                      </span>
                      <span class="mr-2">{{ switchProp(getOrder(), "Taxes") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" class="align-right">
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span>{{ $t('taxable') }} + {{ $t('taxes') }}</span>
                        </v-tooltip>
                        {{ $t('total') }}:
                      </span>
                      <span class="mr-2">{{ switchProp(getOrder(), "Total") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" max-width="600px" v-if="!!editModeStatus">
              <template v-slot:activator="{ on, attrs }">
                <v-row class="mx-1 mb-5" v-bind="attrs" v-on="on">
                  <v-col>
                    <v-row class="castelletto-top castelletto-left">
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                        <span class="mini-label-castelletto"><v-icon class="mr-3">mdi-pencil</v-icon>{{ $t('discP') }}</span><br />
                        <p class="float-right">{{ $numerals(getOrder().orderDiscountPercentage / 100, '0.00 %') }}</p>
                      </v-col>
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                        <span class="mini-label-castelletto"><v-icon class="mr-3">mdi-pencil</v-icon>{{ $t('discountAmount') }}</span><br />
                        <p class="float-right">{{ switchProp(getOrder(), "OrderDiscountAbsolute") | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                      </v-col>
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                        <span class="mini-label-castelletto"><v-icon class="mr-3">mdi-pencil</v-icon>{{ $t('deliveryCharges') }}</span><br />
                        <p class="float-right">{{ switchProp(getOrder(), "DeliveryCharges") | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                      </v-col>
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                        <span class="mini-label-castelletto"><v-icon class="mr-3">mdi-pencil</v-icon>{{ $t('collectionExpenses')}}</span><br />
                        <p class="float-right">{{ switchProp(getOrder(), "CollectionCharges") | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                      </v-col>
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                        <span class="mini-label-castelletto" v-t="'stampDuty'"></span><br />
                        <p class="float-right">{{ 0 | tenantCurrency(false, selectedCurrency.symbol) }}</p>
                      </v-col>
                    </v-row>
                    <v-row class="castelletto-bottom castelletto-left">
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="4">
                        <span class="mini-label-castelletto" v-t="'vatCode'"></span><br />
                        <div style="display: flex; flex-direction: row; justify-content: space-between;"
                          v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                          <span>{{ vatPercentageById(el.vatPercentageId)?.description }}</span>
                          <span>{{ vatPercentageById(el.vatPercentageId)?.percentage ?? 0 }} %</span>
                        </div>
                      </v-col>
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="5">
                        <span class="mini-label-castelletto" v-t="'vatAssessment'"></span><br />
                        <div style="display: flex; flex-direction: row; justify-content: space-between;"
                          v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                          <span>{{ el.qty }} {{ `Item${el.qty > 1 ? `s` : ``}` }}</span>
                          <span>{{ el.documentCurRowTaxable | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                        </div>
                      </v-col>
                      <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                        <span class="mini-label-castelletto" v-t="'tax'"></span><br />
                        <div style="display: flex; flex-direction: row; justify-content: space-between;"
                          v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                          <span>&nbsp;</span>
                          <span>{{ el.documentCurRowTaxes | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="castelletto castelletto-top castelletto-bottom cstelletto-left mt-0 mb-0" cols="12" sm="1">
                    <span class="mini-label-castelletto" v-t="'currency'"></span>
                    <p>{{ currencySymbol }}</p>
                  </v-col>
                  <v-col class="castelletto castelletto-top castelletto-bottom castelletto-right mt-0 mb-0" cols="12" sm="4">
                    <v-row>
                      <v-col cols="12" sm="12" class="align-right">
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                          <span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span v-t="'rowsTotalAfterDiscSurc'"></span>
                            </v-tooltip>
                            {{ $t('subTotal') }}:
                          </span>
                          <span>{{ switchProp(getOrder(), "Subtotal") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" class="align-right">
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                          <span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span v-t="'subtotalDeliveryCollection'"></span>
                            </v-tooltip>
                            {{ $t('taxable') }}:
                          </span>
                          <span>{{ switchProp(getOrder(), "Taxable") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" class="align-right">
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                          <span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span v-t="'taxes'"></span>
                            </v-tooltip>
                            {{ $t('taxes') }}:
                          </span>
                          <span class="mr-2">{{ switchProp(getOrder(), "Taxes") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" class="align-right">
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                          <span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>{{ $t('taxable') }} + {{ $t('taxes') }}</span>
                            </v-tooltip>
                            {{ $t('total') }}:
                          </span>
                          <span class="mr-2">{{ switchProp(getOrder(), "Total") | tenantCurrency(false, selectedCurrency.symbol) }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5" v-t="'update'"></span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field :label="$t('discP')" type="number" v-model="getOrder().orderDiscountPercentage"
                          @change="getOrder().documentCurOrderDiscountAbsolute = 0">
                          <v-icon slot="append">mdi-percent</v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :label="$t('discountAmount')" type="number"
                          v-model="getOrder().documentCurOrderDiscountAbsolute"
                          @change="getOrder().orderDiscountPercentage = 0">
                          <v-icon slot="append">{{ `mdi-currency-${selectedCurrency.name.toLowerCase()}` }}</v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete v-model="getOrder().paymentTermId" :items="paymentTermsDropdown" :label="$t('payment')"
                          @change="selectedPaymentTerm"></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="getOrder().documentCurCollectionCharges" hide-details="auto"
                          :label="$t('collectionExpenses')" min="0" type="number">
                          <v-icon slot="append">{{ `mdi-currency-${selectedCurrency.name.toLowerCase()}` }}</v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :label="$t('deliveryCharges')" type="number" min="0"
                          v-model="getOrder().documentCurDeliveryCharges">
                          <v-icon slot="append">{{ `mdi-currency-${selectedCurrency.name.toLowerCase()}` }}</v-icon>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false" v-t="'close'"></v-btn>
                  <v-btn color="blue darken-1" text @click="closeAndSave()" v-t="'confirm'"></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-if="showUpdateHeaderOrderDialog" :value="true" width="1000">
      <UpdateOrderHeader :order="getOrder()" :clone="false" @closeCloneOrderDialog="closeCloneOrderDialog">
      </UpdateOrderHeader>
    </v-dialog>


    <v-dialog transition="dialog-bottom-transition" v-model="dialogMassiveDuplicateCSV"
              :width="!massiveDuplicateCsv.content.length ? 500 : 900">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block tile v-bind="attrs" v-on="on">Upload CSV</v-btn>
        <!-- <v-btn color="primary">Load CSV</v-btn> -->
      </template>
      <v-card v-if="!massiveDuplicateCsv.content.length">
        <v-card-title class="headline grey lighten-2" v-t="'uploadCsv'"></v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" sm="12">
              <v-select v-model="massiveDuplicateCsv.separator" :items="separators" :label="$t('separator')" outlined></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-file-input outlined v-model="massiveDuplicateCsv.file" :label="$t('fileInput')" filled
                            @change="readMassiveCsvContent"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialogMassiveDuplicateCsv" v-t="'close'"></v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="!!massiveDuplicateCsv.content.length">
        <v-card-text class="vh60-scrollable">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" v-for="col in getColumns(massiveDuplicateCsv)">{{ col.text }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="el, i in massiveDuplicateCsv.content" :key="i">
                <td v-for="col in getColumns(massiveDuplicateCsv)">{{ el[col.text] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-if="!!massiveDuplicateCsv.export">
          <v-row>
            <v-col cols="12" :sm="getColumns(massiveDuplicateCsv).length > 2 ? 4 : 6">
              <v-select v-model="massiveDuplicateCsv.customerCode" :items="columns" :label="$t('customerCode')" clearable outlined></v-select>
            </v-col>
            <v-col cols="12" :sm="getColumns(massiveDuplicateCsv).length > 2 ? 4 : 6">
              <v-select v-model="massiveDuplicateCsv.businessName" :items="columns" :label="$t('businessName', {r:''})" clearable
                        outlined></v-select>
            </v-col>
            <v-col cols="12" sm="4" v-if="getColumns(massiveDuplicateCsv).length > 2">
              <v-select v-model="massiveDuplicateCsv.address" :items="columns" :label="$t('address')" clearable
                        outlined></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialogMassiveDuplicateCsv" v-t="'close'"></v-btn>
          <v-btn color="primary" text @click="selectMassiveDuplicateColumns" v-t="'preview'"></v-btn>
          <v-btn color="primary" text @click="massiveDuplicateConfirm" v-if="massiveDuplicateCsv.export" v-t="'upload'"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-speed-dial v-if="!!showWarmholeFab" v-model="wormholeFab" :right="true" :bottom="true" direction="left" transition="slide-x-reverse-transition" fixed>
      <template v-slot:activator>
        <v-btn v-model="wormholeFab" color="primary" dark fab style="overflow: hidden;">
          <v-icon v-if="wormholeFab">mdi-close</v-icon>
          <v-img v-else :src="require('@/assets/wormhole.png')"
            style="width: 7px; height: auto; margin: 6px 1px 0 1px;" />
        </v-btn>
      </template>
      <v-row style="max-height: 60px; width: 50vw;">
        <v-col cols="12" sm="1"
          style="background-color: #35838f; padding: 1px 0; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <v-img :src="require('@/assets/businesscentral_logo.png')" style="width: 50px; height: auto;" />
        </v-col>
        <v-col cols="12" sm="11" style="padding: 1px 0; background-color: #fff;">
          <div
            style="display: flex; flex-direction: row; justify-content: start; align-items: center; height: 80%; margin: 5px 0px; border-top: #d3d6da 1px solid; border-bottom: #d3d6da 1px solid;">
            <div
              style="display:flex; flex-direction: row; justify-content: center; align-items: center; font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;-webkit-font-smoothing: antialiased; font-size: 14px; font-weight: 400; color: #323130; vertical-align: middle; margin: 0 5px; cursor: pointer;"
              @click="openWithWormhole()">
              <v-img :src="require('@/assets/bc/Action_Order_mono_16x16.png')"
                style="width: 16px; height: 16px; margin-top: -2px; margin-right: 2px;" />
              {{ $t('order') }}
            </div>
            <div
              style="display:flex; flex-direction: row; justify-content: center; align-items: center; font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;-webkit-font-smoothing: antialiased; font-size: 14px; font-weight: 400; color: #323130; vertical-align: middle; margin: 0 5px; cursor: pointer;"
              @click="forceSyncWithBc()">
              <v-img :src="require('@/assets/bc/Action_RefreshLines_mono_16x16.png')"
                style="width: 16px; height: 16px; margin-top: -2px; margin-right: 2px;" />
              {{ $t('forceSync') }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-speed-dial>
  </div>
</template>


<script>
// import TelerikGrid from "@/components/TelerikGrid";
import {call, dispatch, get, sync} from "vuex-pathify";
import BooleanIcon from "./app/BooleanIcon";

export default {
  name: "UpdateOrder",
  emits: ["click"],
  props: ['orderId', 'historical'],
  data: () => ({
    dialogCSV: false,
    dialogMassiveDuplicateCSV: false,
    csv: {
      separator: ';',
      content: [],
      export: false,
      code: -1,
      quantity: -1,
      discount: -1
    },
    massiveDuplicateCsv: {
      separator: ';',
      content: [],
      export: false,
      businessName: "",
      customerCode: "",
      address: ""
    },
    columns: [{ value: 0, text: 'A' }, { value: 1, text: 'B' }, { value: 2, text: 'C' }, { value: 3, text: 'D' }, { value: 4, text: 'E' }, { value: 5, text: 'F' }, { value: 6, text: 'G' }, { value: 7, text: 'H' }, { value: 8, text: 'I' }, { value: 9, text: 'J' }, { value: 10, text: 'K' }, { value: 11, text: 'L' }, { value: 12, text: 'M' }, { value: 13, text: 'N' }, { value: 14, text: 'O' }, { value: 15, text: 'P' }, { value: 16, text: 'Q' }, { value: 17, text: 'R' }, { value: 18, text: 'S' }, { value: 19, text: 'T' }, { value: 20, text: 'U' }, { value: 21, text: 'V' }, { value: 22, text: 'W' }, { value: 23, text: 'X' }, { value: 24, text: 'Y' }, { value: 25, text: 'Z' },],
    wormholeFab: null,
    showNotes: false,
    currentCurrencies: [],
    order: {},
    showUpdateHeaderOrderDialog: false,
    showOptionDialog: false,
    showUpdatePriceDialog: null,
    selectedPriceCatalogue: null,
    cancelConfirmDialog: null,
    isCancelingOrder: false,
    editModeStatus: false,
    updateRowsSlide: false,
    step1Validation: true,
    step2Validation: false,
    step3Validation: false,
    step4Validation: false,
    step5Validation: false,
    toggle_import: null,
    validStepOne: false,
    dialogCut: false,
    customerLocations: [],
    invoicingCustomerLocations: [],
    shippingCustomerLocations: [],
    discountType: 'currency',
    discountValue: 0,
    packageTypes: ["BOX", "PALLET"],
    customerLocationsDropdown: [],
    customerIbanDropdown: [],
    validOrder: false,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    docDatePicker: false,
    currentStep: 1,
    orderRows: [],
    selectedCustomer: {},
    selectedCurrency: {},
    notesToShow: {
      customerBusinessName: null,
      customerNotes: null,
      customerAdministrativeNotes: null,
      customerHasPaymentProblems: false,
      invoicingBusinessName: null,
      invoicingNotes: null,
      invoicingAdministrativeNotes: null,
      invoicingHasPaymentProblems: false,
      shippingBusinessName: null,
      shippingNotes: null,
      shippingAdministrativeNotes: null,
      shippingHasPaymentProblems: false
    },
    // internalOrderDate: new Date().toISOString().substr(0, 10),
    // internalEstimatedDeliveryDate: new Date().toISOString().substr(0, 10),
    modelLang: {
      langTag: ''
    },
    interval: null
  }),
  components: {
    // TelerikGrid,
    BooleanIcon,
  },
  created() {
    this.initialize()
  },
  methods: {
    readContent: function (data) {
      if (!data) return;
      var reader = new FileReader();
      reader.readAsText(data);
      reader.onload = () => {
        this.readCsv(reader, this.csv);

        /*reader.result.split(/\r?\n|\r/).filter(el => !!el).forEach((el) => {
          this.csv.content.push(el.split(this.csv.separator).reduce((a, b, i) => {
            a[this.columns.find(el => el.value == i)?.text ?? ''] = b;
            return a;
          }, {}));
        });*/
      }
    },
    readMassiveCsvContent: function (data) { console.log(data);
      if (!data) return;
      var reader = new FileReader();
      reader.readAsText(data);
      reader.onload = () => {
        this.readCsv(reader, this.massiveDuplicateCsv);

        /*reader.result.split(/\r?\n|\r/).filter(el => !!el).forEach((el) => {
          this.massiveDuplicateCsv.content.push(el.split(this.massiveDuplicateCsv.separator).reduce((a, b, i) => {
            a[this.columns.find(el => el.value == i)?.text ?? ''] = b;
            return a;
          }, {}));
        });*/
      }
    },
    readCsv: function (reader, csv) {
      reader.result.split(/\r?\n|\r/).filter(el => !!el).forEach((el) => {
        csv.content.push(el.split(csv.separator).reduce((a, b, i) => {
          a[this.columns.find(el => el.value == i)?.text ?? ''] = b;
          return a;
        }, {}));
      });
    },
    getColumns: function (csv) {
      return this.columns.slice(0, csv.content.reduce((a, b) => {
        a = Object.keys(b).length > a ? Object.keys(b).length : a;
        return a;
      }, 0));
    },
    closeDialogCsv: function () {
      this.csv.content.length = 0;
      this.csv.separator = ';';
      this.csv.export = false;
      this.csv.code = -1;
      this.csv.quantity = -1;
      this.csv.discount = -1;
      this.dialogCSV = false;
    },
    closeDialogMassiveDuplicateCsv: function () {
      this.massiveDuplicateCsv.content.length = 0;
      this.massiveDuplicateCsv.separator = ';';
      this.massiveDuplicateCsv.export = false;
      this.massiveDuplicateCsv.customerCode = "";
      this.massiveDuplicateCsv.businessName = "";
      this.massiveDuplicateCsv.address = "";
      this.dialogMassiveDuplicateCSV = false;
    },
    selectColumns: function () {
      this.csv.export = true;
    },
    selectMassiveDuplicateColumns: function () {
      this.massiveDuplicateCsv.export = true;
    },
    confirm: function () {
      this.loadRes();

      setTimeout(() => {
        if (this.csv.code < 0 || this.csv.quantity < 0 || (this.getColumns(this.csv).length > 2 && this.csv.discount < 0)) return;
        let result = this.csv.content.map((x) => ({
          code: x[this.columns.find(x => x.value == this.csv.code).text].trim(),
          quantity: +x[this.columns.find(x => x.value == this.csv.quantity).text].match(/\d+/)?.flat()[0],
          discount: +(x[this.columns.find(x => x.value == this.csv.discount)?.text]?.match(/\-?\d+([.,]\d+)/)?.flat()[0]?.replace(',', '.')) ?? 0
        })).filter(x => !!this.getItemByCode(x.code)?.id);

        let counter = this.orderRows.length;
        this.orderRows.push(...result.map((x, i) => ({
          rowNumber: (counter + i) * 10,
          itemCode: `${x.code}`,
          itemDescription: `${this.getDescriptionByCode(x.code)}`,
          quantity: x.quantity || 1,
          documentCurRowOriginalPrice: (this.itemPrices.find(itemPrice => itemPrice.itemId === this.getItemByCode(x.code)?.id) || {})?.price ?? 0.01,
          originalPrice: (this.itemPrices.find(itemPrice => itemPrice.itemId === this.getItemByCode(x.code)?.id) || {})?.price ?? 0.01,
          rowDiscountPercentage: +(x.discount) ?? 0,
          isGift: false,
          isLoanForUse: false,
          notes: "",
        })))

        this.apiOrderIdOrderRowsPatch({
          orderId: this.getOrder().id,
          collection: this.orderRows
        })
          .then((res) => {
            this.orderRows = res;
            this.getFooter();
            this.closeDialogCsv();
          })
          .finally(() => this.unloadRes());
      }, 1000);
    },
    massiveDuplicateConfirm: function () {
      this.loadRes();

      setTimeout(() => {

        const result = this.massiveDuplicateCsv.content.map((x) => ({
          customerCode: x[this.columns.find(x => x.value == this.massiveDuplicateCsv.customerCode).text].trim(),
          businessName: x[this.columns.find(x => x.value == this.massiveDuplicateCsv.businessName).text].trim(),
          address: x[this.columns.find(x => x.value == this.massiveDuplicateCsv.address).text].trim()
        }));

        const order = this.getOrder();

        let massiveDuplicateDto = [];
        massiveDuplicateDto.push(...result.map((x, i) => ({
          businessName: `${x.businessName}`,
          customerCode: `${x.customerCode}`,
          address: `${x.address}`,
          originalOrderId: order.id,
          originalOrderCode: order.code,
          orderStatusId: order.orderStatusId
        })))

        console.log(massiveDuplicateDto);
        this.apiOrderMassiveDuplicate(massiveDuplicateDto)
            .then((res) => {
              dispatch('app/showSuccess', "Massive duplication finished! "+res+" orders correctly duplicated.", {root: true});
              this.closeDialogMassiveDuplicateCsv();
            })
            .finally(() => this.unloadRes());
      }, 1000);
    },
    closeCloneOrderDialog: function () {
      this.showUpdateHeaderOrderDialog = !this.showUpdateHeaderOrderDialog;
      this.initialize();
    },
    editMode: function () {
      if (!!this.editModeStatus) {
        //this.order.orderDate = this.$moment.utc(this.order.orderDate).local().format('YYYY-MM-DD');
        
        // this.order.estimatedDeliveryDate = this.$moment.utc(this.order.estimatedDeliveryDate).format('YYYY-MM-DD');
        this.editOrder(this.order).then((res) => {
          this.order = res;
        });
      } else {
        this.getItemPriceListOfCatalog(this.getOrder().priceCatalogId);

        // this.order.documentCurRowsTotal = this.order.documentCurSubtotal = this.order.documentCurTaxable = this.order.documentCurTaxes = this.order.total = 0;

        this.internalEstimatedDeliveryDate = this.$moment.utc(this.getOrder().estimatedDeliveryDate).local().format('YYYY-MM-DD'); //new Date(this.getOrder().estimatedDeliveryDate).toISOString().substr(0, 10);
        this.internalOrderDate = this.$moment.utc(this.getOrder().orderDate).local().format('YYYY-MM-DD');

      }
      this.editModeStatus = !this.editModeStatus;
      this.updateRowsSlide = !this.updateRowsSlide;
    },
    getPreviousOrderStatus: function () {
      const orderStatusCode = this.orderStatus.find(el => el.id === this.getOrder().orderStatusId)?.code || null;
      switch (orderStatusCode) {
        case "WIP":
        case "CANCELED":
          return 'TRANSMITTED'; //this.orderStatus.find(el => el.code === 'TRANSMITTED');
        case "PROCESSED":
          return 'WIP'; //this.orderStatus.find(el => el.code === 'WIP');
      }
    },
    restorePreviousOrderStatus: function () {
      const orderStatusCode = this.orderStatus.find(el => el.id === this.getOrder().orderStatusId)?.code || null;
      switch (orderStatusCode) {
        case "WIP":
        case "CANCELED":
          this.changeOrderStatus('TRANSMITTED');
          break;
        case "PROCESSED":
          this.changeOrderStatus('WIP');
          break;
      }
      this.showOptionDialog = !this.showOptionDialog;
    },
    updateHeader: function () {
      this.showUpdateHeaderOrderDialog = !this.showUpdateHeaderOrderDialog;
    },
    getAgentName: function (label, item) {
      return `${label}: ${(this.agents.find(x => x.id === item) || {}).name}`;
    },
    getCommercialSpecialistName: function (label, item) {
      return `${label}: ${(this.commercialSpecialists.find(x => x.id === item) || {}).name}`;
    },
    getBrandName: function (item) {
      return (this.families.find(x => x.id === item) || {}).description;
    },
    getCustomerName: function (item) {
      return (this.customers.find(x => x.id === item) || {}).businessName || "";
    },
    getPO: function (label, item) {
      return `${label}: ${item || ''}`;
    },
    getOrderTypeName: function (item) {
      return (this.orderTypes.find(x => x.id === item) || {}).description || "";
    },
    getPriceCatalogName: function (label, item) {
      return `${label}: ${(this.priceCatalogs.find(x => x.id === item) || {}).description || ""}`;
    },
    getPaymentTypeName: function (label, item) {
      return `${label}: ${(this.paymentTerms.find(x => x.id === item) || {}).description}`;
    },
    getPaymentMethodName: function (label, item) {
      return `${label}: ${(this.paymentMethods.find(x => x.id === item) || {}).description}`;
    },
    getCustomerIban: function (label, item) {
      return `${label}: ${item}`;
    },
    getOrderStatus: function (orderStatusId) {
      return this.orderStatus.find(x => x.id === orderStatusId) || {};
    },
    getCustomerLocationName: function (customerType, locationId) {
      let customerLocation = null;
      switch (customerType) {
        case 'customer':
          customerLocation = this.customerLocations.find(x => x.id === locationId) || {};
          break;
        case 'invoicing':
          customerLocation = this.invoicingCustomerLocations.find(x => x.id === locationId) || {};
          break;
        case 'shipping':
          customerLocation = this.shippingCustomerLocations.find(x => x.id === locationId) || {};
          break;
      }
      return `${customerLocation.address ?? ``}
        <br />${customerLocation.postalCode ? `(${customerLocation.postalCode})` : ``}${customerLocation.city ?? ``} ${customerLocation.county ?? customerLocation.provice ?? ''}
        <br />${customerLocation.country ?? ``}`;
    },
    getEstimatedDate: function (date, mandatory) {
      if (!date) return "";

      if (mandatory) {
        return `${ this.$t('estimatedDeliveryDate') } on ${this.estimatedDeliveryDateFormatted} (${mandatory})`;
      }
      return `${ this.$t('estimatedDeliveryDate') } on ${this.estimatedDeliveryDateFormatted}`;
    },
    getPackageType: function (type, maxHeight) {
      if (maxHeight > 0) {
        return `${ this.$t('packagingType') } ${type} - ${ this.$t('maxParam', {param:this.$t('height')}) }: (${maxHeight})`;
      }
      return `${ this.$t('packagingType') } ${type}`;
    },
    getHeaderPhrase: function () {
      const brandName = (this.families.find(x => x.id === this.getOrder().familyId) || {}).description;
      const customerName = (this.customers.find(x => x.id === this.getOrder().customerId) || {}).businessName || "";
      const orderTypeName = (this.orderTypes.find(x => x.id === this.getOrder().orderTypeId) || {}).description || "";

      return `${ this.$t('order') } ${brandName} #${this.getOrder().code} of ${this.orderDateFormatted} (${orderTypeName}) - ${customerName}`;
    },
    getOriginalPrice: function () {
      return this.selectedCurrency.name == 'EUR' ? 'accountingCurRowOriginalPrice' : 'documentCurRowOriginalPrice'
    },
    getAmountAfterDiscount: function () {
      return this.selectedCurrency.name == 'EUR' ? 'accountingCurRowAmountAfterDiscount' : 'documentCurRowAmountAfterDiscount'
    },
    switchProp: function (elem, prop, row = false) {
      return elem[`${this.selectedCurrency.name == 'EUR' ? `accountingCur` : `documentCur`}${!!row ? `Row` : ``}${prop}`];
    },
    applyNewOrderDate: function() {
      this.getOrder().orderDate = this.$moment.utc(this.internalOrderDate).local();
      this.menu = false;
    },
    applyNewOrderDeliveryDate: function() {
      this.getOrder().estimatedDeliveryDate = this.$moment.utc(this.internalEstimatedDeliveryDate).local();
      this.menu2 = false;
    },
    initialize: function () {
      this.apiLanguageGet();

      let promise = window.location.pathname.includes('historical')
        ? this.apiHistoricalOrderIdGet(this.orderId)
        : this.apiOrderIdGet(this.orderId);

      promise.then((result) => {
        this.order = result;
        this.selectedPriceCatalogue = this.order.priceCatalogId;
        this.initCustomerCollection();
        this.initItemCollection();
        this.initAgentCollection();
        this.initCommercialSpecialistCollection();
        this.initFamilyCollection();
        this.initPriceCatalogCollection();
        let promiseRows = window.location.pathname.includes('historical')
          ? this.apiHistoricalOrderIdOrderRowsGet(this.orderId)
          : this.apiOrderIdOrderRowsGet(+this.getOrder().id);
        promiseRows.then((result) => {
            this.orderRows.length = 0;
            this.orderRows.push(...result);
          });
        this.getCustomersForNewOrder({
          agentId: this.getOrder().agentId,
          familyId: this.getOrder().familyId,
        });
        this.initOrderTypesCollection();
        this.initOrderStatusCollection();
        this.initVatCollection();
        this.selectedCustomer = this.customers.find(el => el.id === this.getOrder().customerId);
        this.getItemPriceListOfCatalog(this.getOrder().priceCatalogId);
        this.initPaymentTermCollection();
        this.initPaymentMethodCollection();
        this.apiCustomersIdIbansGet(this.getOrder().customerId)
          .then((res) => {
            this.customerIbanDropdown = res.map(item => ({
              text: item.fullAddress,
              value: item.id
            }));
          });
        this.apiCustomersIdLocationsGet(this.getOrder().customerId)
          .then((res) => {
            this.customerLocations = res;
            this.customerLocationsDropdown = res.map(item => ({
              text: item.name + " - " + item.address,
              value: item.id
            }));
          });
        if (!!this.getOrder().hasDifferentInvoicingCustomer) {
          this.apiCustomersIdLocationsGet(this.getOrder().invoicingCustomerId)
            .then((res) => {
              this.invoicingCustomerLocations = res;
            })
        }

        if (!!this.getOrder().hasDifferentShippingCustomer) {
          this.apiCustomersIdLocationsGet(this.getOrder().shippingCustomerId)
            .then((res) => {
              this.shippingCustomerLocations = res;
            });
        }
        this.selectedPaymentTerm();
        this.selectedPaymentMethod();
        this.initCurrenciesCollection()
          .then(response => {
            this.currentCurrencies.length = 0;
            this.currentCurrencies.push(...response.filter(el => el.name == "EUR" || el.id == this.getOrder().documentCurrencyId));
            this.selectedCurrency = this.currentCurrencies.length > 1 ?
            this.currentCurrencies.find(el => el.name != 'EUR')
            : this.currentCurrencies[0];
          });
      });
    },
    onCustomerUpdate: function () {
      if (!!this.order.customerId) {
        this.apiCustomersIdPriceCatalogFamilyIdGet({
          familyId: this.orderDraft.familyId,
          customerId: this.orderDraft.customerId
        }).then((result) => {
          this.orderDraft.priceCatalogId = result.find(x => !!x.default)?.priceCatalogId || this.priceCatalogDropdown[0].value || null;
        });
        this.selectedCustomer = this.customers.find(el => el.id == this.orderDraft.customerId) || {};
        this.apiCustomersIdLocationsGet(this.selectedCustomer.id)
          .then((res) => {
            this.customerLocationsDropdown = res.map(item => ({
              text: item.name + " - " + item.address,
              value: item.id
            }));
            this.orderDraft.customerLocationId = (res.find(x => !!x.default) || {}).id || null;
          });
        this.orderDraft.vatPercentageId = this.selectedCustomer.defaultVatRate || null;
        this.orderDraft.paymentTermId = this.selectedCustomer.defaultPaymentTermId || null;
        this.orderDraft.paymentMethodId = this.selectedCustomer.defaultPaymentMethodId || null;
        if (soft === true) {
          this.orderDraft.invoicingCustomerId = this.orderDraft.invoicingCustomerId || this.selectedCustomer.defaultInvoicingCustomerId;
          this.orderDraft.invoicingCustomerLocationId = this.orderDraft.invoicingCustomerLocationId || this.selectedCustomer.defaultInvoicingCustomerLocationId;
          this.orderDraft.shippingCustomerId = this.orderDraft.shippingCustomerId || this.selectedCustomer.defaultShippingCustomerId;
          this.orderDraft.shippingCustomerLocationId = this.orderDraft.shippingCustomerLocationId || this.selectedCustomer.defaultShippingCustomerLocationId;
        } else {
          this.orderDraft.invoicingCustomerId = this.selectedCustomer.defaultInvoicingCustomerId;
          this.orderDraft.invoicingCustomerLocationId = this.selectedCustomer.defaultInvoicingCustomerLocationId;
          this.orderDraft.shippingCustomerId = this.selectedCustomer.defaultShippingCustomerId;
          this.orderDraft.shippingCustomerLocationId = this.selectedCustomer.defaultShippingCustomerLocationId;
        }
        this.orderDraft.hasDifferentInvoicingCustomer = (!!this.orderDraft.invoicingCustomerId && this.orderDraft.invoicingCustomerId !== null) 
          && (!!this.orderDraft.invoicingCustomerLocationId && this.orderDraft.invoicingCustomerLocationId !== null);
        this.orderDraft.hasDifferentShippingCustomer = (!!this.orderDraft.shippingCustomerId && this.orderDraft.shippingCustomerId !== null) 
          && (!!this.orderDraft.shippingCustomerLocationId && this.orderDraft.shippingCustomerLocationId !== null);
        this.invoicingCustomerChanged();
        this.shippingCustomerChanged();
      }

      this.invoicingCustomerLocations.length = 0;
      this.invoicingCustomerLocationsDropdown.length = 0;
      this.shippingCustomerLocations.length = 0;
      this.shippingCustomerLocationsDropdown.length = 0;

      this.getOrder().invoicingCustomerId = null;
      this.getOrder().shippingCustomerId = null;

      this.getOrder().invoicingCustomerLocationId = null;
      this.getOrder().shippingCustomerLocationId = null;

      this.getOrder().hasDifferentInvoicingCustomer = false;
      this.getOrder().hasDifferentShippingCustomer = false;

      this.apiCustomersIdLocationsGet(this.getOrder().customerId)
        .then((res) => {
          this.customerLocations = res;
          this.customerLocationsDropdown = res.map(item => ({
            text: item.name + " - " + item.address,
            value: item.id
          }));
          this.getOrder().customerLocationId = this.customerLocationsDropdown[0]?.value;
        });
    },
    onInvoicingCustomerUpdate: function () {
      this.apiCustomersIdLocationsGet(this.getOrder().invoicingCustomerId)
        .then((res) => {
          this.invoicingCustomerLocations = res;
          this.invoicingCustomerLocationsDropdown = res.map(item => ({
            text: item.name + " - " + item.address,
            value: item.id
          }));
          this.getOrder().invoicingCustomerLocationId = this.invoicingCustomerLocationsDropdown[0]?.value;
        });
    },
    onShippingCustomerUpdate: function () {
      this.apiCustomersIdLocationsGet(this.getOrder().shippingCustomerId)
        .then((res) => {
          this.shippingCustomerLocations = res;
          this.shippingCustomerLocationsDropdown = res.map(item => ({
            text: item.name + " - " + item.address,
            value: item.id
          }));
          this.getOrder().shippingCustomerLocationId = this.shippingCustomerLocationsDropdown[0]?.value;
        });
    },
    onPriceCatalogUpdate: function () {
      this.showUpdatePriceDialog = !this.showUpdatePriceDialog;
    },
    restorePriceUpdate: function () {
      this.getOrder().priceCatalogId = this.selectedPriceCatalogue;
      this.showUpdatePriceDialog = !this.showUpdatePriceDialog;
    },
    forcePriceUpdate: function () {
      this.getItemPriceListOfCatalog(this.getOrder().priceCatalogId)
        .then((res) => {
          this.orderRows = this.orderRows.map(el => {
            let item = this.items.find(x => x.code === el.itemCode),
              documentCurRowOriginalPrice = null;
            if (!!item) documentCurRowOriginalPrice = res.find(x => x.itemId == item.id);
            if (!!documentCurRowOriginalPrice) el.documentCurRowOriginalPrice = documentCurRowOriginalPrice.price || 0.01;
            return el;
          });

          this.saveRows(this.orderRows);

          this.showUpdatePriceDialog = !this.showUpdatePriceDialog;
        });
    },
    getOrder: function () {
      return this.order || {};
    },
    getCustomer: function () {
      return this.selectedCustomer || {};
    },
    showPrice: function (currency) {
      this.selectedCurrency = currency;
    },
    minOrderReached: function () {
      return (!!this.selectedCustomer && this.selectedCustomer?.minOrder == 0.00) || this.getFooter().rowsAmountsBeforeDiscount >= this.selectedCustomer?.minOrder;
    },
    getFooter: function () {
      let result = this.orderRows.reduce(
        (a, b) => {
          a = a || {};
          a.totalQuantity += !!b.quantity ? +b.quantity : 0;
          a.rowsAmountsBeforeDiscount += !!b.documentCurRowAmountBeforeDiscount ? +b.documentCurRowAmountBeforeDiscount : 0;
          a.rowsDiscountAmount += !!b.documentCurRowDiscountAmount ? +b.documentCurRowDiscountAmount : 0;
          a.documentCurRowsTotal += !!b.documentCurRowAmountAfterDiscount ? +b.documentCurRowAmountAfterDiscount : 0;
          return a;
        },
        { totalQuantity: 0, documentCurRowsTotal: 0.0, rowsAmountsBeforeDiscount: 0.0, rowsDiscountAmount: 0.0 }
      );
      return result;
    },
    selectedPaymentTerm: function () {
      this.calculateDeadlines(this.getOrder());
      const selectedPaymentTerm = this.paymentTerms.find(x => x.id === this.getOrder().paymentTermId);
      this.getOrder().documentCurCollectionCharges = selectedPaymentTerm?.collectionCharges;
    },
    selectedPaymentMethod: function () {
      const selectedPaymentMethod = this.paymentMethods.find(x => x.id === this.getOrder().paymentMethodId);
    },
    applyDiscount: function () {
      if (this.discountType === 'percentage') {
        this.getOrder().documentCurOrderDiscountAbsolute = 0;
      } else if (this.discountType === 'currency') {
        this.getOrder().orderDiscountPercentage = 0;
      }
    },
    getCustomerLocations: function () {
      if (!!this.getOrder().customerId) {
        this.customerLocationsGet(this.getOrder().customerId);
      }
    },

    updateCollection: function (p) {
      this.orderRows = p;
      
      this.saveRows(p);
    },

    saveRows: function (p) {
      this.apiOrderIdOrderRowsPatch({
        orderId: this.getOrder().id,
        collection: p
      })
        .then((res) => {
          this.orderRows = res;
          this.getFooter();
        });
    },

    changeOrderStatus: function (status_code) {
      const selectedOrderStatus = this.orderStatus.find(x => x.code === status_code);
      if (selectedOrderStatus != null) {
        this.apiOrderIdChangeStatusToPatch({
          orderId: this.getOrder().id,
          newStatusId: selectedOrderStatus.id
        }).then((orderStatusId) => {
          this.order.orderStatusId = orderStatusId; //selectedOrderStatus.id;
          this.showNotes = false;
        });
      }
    },

    cancelOrder: function () {
      const selectedOrderStatus = this.orderStatus.find(x => x.code === "CANCELED");
      if (selectedOrderStatus != null) {
        this.cancelConfirmDialog = true;
        this.apiOrderIdChangeStatusToPatch({
          orderId: this.getOrder().id,
          newStatusId: selectedOrderStatus.id
        }).then(() => {
          this.order.orderStatusId = selectedOrderStatus.id;
          this.isCancelingOrder = false;
          this.cancelConfirmDialog = false;
        });
      }
    },

    isInRole: function (roles) {
      return this.$store.get("auth/isInRole", roles);
    },
    checkIfAdministrativeNotesForCustomers() {
      let customer = this.customers.find(x => x.id === this.order.customerId);
      let invoicingCustomer = this.order.invoicingCustomerId != null ? this.customers.find(x => x.id === this.order.invoicingCustomerId) || null : null;
      let shippingCustomer = this.order.shippingCustomerId != null ? this.customers.find(x => x.id === this.order.shippingCustomerId) || null : null;
      let hasNotesToShow = false;
      this.notesToShow = {
        customerBusinessName: (customer || {}).businessName || null,
        customerNotes: (customer || {}).notes || null,
        customerAdministrativeNotes: (customer || {}).administrativeNotes || null,
        customerHasPaymentProblems: (customer || {}).hasPaymentProblems || false,
        invoicingBusinessName: (invoicingCustomer || {}).businessName || null,
        invoicingNotes: (invoicingCustomer || {}).notes || null,
        invoicingAdministrativeNotes: (invoicingCustomer || {}).administrativeNotes || null,
        invoicingHasPaymentProblems: (invoicingCustomer || {}).hasPaymentProblems || false,
        shippingBusinessName: (shippingCustomer || {}).businessName || null,
        shippingNotes: (shippingCustomer || {}).notes || null,
        shippingAdministrativeNotes: (shippingCustomer || {}).administrativeNotes || null,
        shippingHasPaymentProblems: (shippingCustomer || {}).hasPaymentProblems || false,
      };

      hasNotesToShow = Object.values(this.notesToShow).some(x => !!x);

      if (!!hasNotesToShow) {
        this.showNotes = true;
      } else {
        this.notesToShow = {
          customerBusinessName: null,
          customerNotes: null,
          customerAdministrativeNotes: null,
          customerHasPaymentProblems: false,
          invoicingBusinessName: null,
          invoicingNotes: null,
          invoicingAdministrativeNotes: null,
          invoicingHasPaymentProblems: false,
          shippingBusinessName: null,
          shippingNotes: null,
          shippingAdministrativeNotes: null,
          shippingHasPaymentProblems: false
        }
        this.changeOrderStatus('WIP');
      }
    },
    getVatRateDescription: function () {
      const vatRate = this.vatRates.find(el => el.id === this.getOrder().oldVatPercentageId) || {};
      return `${vatRate.code} - ${vatRate.description} (${this.$numerals(vatRate.percentage / 100, '0.00 %')})`
    },
    // getFormattedCurrency: function (value) {
    //   return `${this.selectedCurrency.symbol} ${this.$numerals(value, `0,0.00`)}`;
    // },
    openWithWormhole() {
      window.open(`https://businesscentral.dynamics.com/8b6b26af-8ce9-4a1b-8c5f-0487da5a36fa/MyFamilyUSA-Production/?company=MyFamily USA, Inc.&page=42&filter=\'No.\' is \'${this.getOrder().code}\'`, '_blank');
    },
    forceSyncWithBc() {
      this.apiOrderSyncIdPost(this.orderId);
    },
    applyTaxes: function () {
      this.editOrder(this.order)
        .then(response =>
          this.apiOrderIdApplyTaxesPost(+this.orderId)
            .then(this.initialize))
        .catch(console.error);
    },
    translateItemDescription: async function () {
      let collection = [];
      this.orderRows.forEach(async (orderRow) => {
        let itemId = this.items?.find(el => el.code === orderRow.itemCode)?.id || 0;
        let respopnse = await this.apiItemIdWithTranslationsGet(itemId)
        let description = respopnse?.translations?.find(el => el.langTag == this.modelLang.langTag)?.translationValue ?? '';
        orderRow.itemDescription = description || orderRow.itemDescription;
        collection.push(orderRow);
      });
      setInterval(() => {

        if (collection.length === this.orderRows.length) {
          this.apiOrderIdOrderRowsPatch({
            orderId: this.getOrder().id,
            collection: collection
          })
            .then((res) => {
              this.orderRows = res;
              this.getFooter();
              window.location.reload();
            });
        }
      }, 1000);

    },
    applyQty: function (el, b) {
      el.item.qty = b;
      let temp = [...this.orderRows];
      this.orderRows.length = 0;
      this.orderRows.push(...temp);
    },
    handleCloningOrderWithPartialRows: function () {
      let input = this.orderRows.filter(x => !!x.qty);
      this.apiCutOrderFromOrderRows(input)
        .then((res) => {
          this.$window.location.assign(`/orders/${res.id}`);
        })
        .finally(() => {
          this.dialogCut = false;
        })

    },
    getCastellettoVATInfo: function () {
      return this.orderRows.reduce((a, b) => {
        if (!a?.find(x => x.vatPercentageId == b.vatPercentageId)) {
          a.push({
            vatPercentageId: b.vatPercentageId,
            qty: 1,
            documentCurRowTaxable: b.documentCurRowTaxable,
            documentCurRowTaxes: b.documentCurRowTaxes,
          })
        } else {
          let temp = a.find(x => x.vatPercentageId == b.vatPercentageId);
          temp.qty += 1;
          temp.documentCurRowTaxable += b.documentCurRowTaxable;
          temp.documentCurRowTaxes += b.documentCurRowTaxes;
        }
        return a;
      }, []);
    },
    closeAndSave: function () {
      this.editOrder(this.order).then((res) => {
        this.order = res;
        this.dialog = false;
      });
    },
    forceRecalculationOfOrderTotal: function() {
      this.apiOrderIdRecalculateTotalsPost(this.getOrder().id).then(() => {
        this.initialize();
      });
    },
    massiveDuplicate: function() {
      this.dialogMassiveDuplicateCSV = true;
      console.log(this.dialogMassiveDuplicateCSV);
    },
    ...call("utils", ["getISOString"]),
    ...call("items", ["initItemCollection"]),
    ...call("agents", ["initAgentCollection"]),
    ...call("families", ["initFamilyCollection"]),
    ...call("customers", ["initCustomerCollection", "apiCustomersIdLocationsGet", "apiCustomersIdIbansGet",]),
    ...call("priceCatalogs", ["initPriceCatalogCollection", "getItemPriceListOfCatalog",]),
    ...call("customerLocations", ["customerLocationsGet"]),
    ...call("orders", [
      "getCustomersForNewOrder",
      "initOrderTypesCollection",
      "initOrderStatusCollection",
      "initVatPercentagesCollection",
      "initPaymentTermCollection",
      "initPaymentMethodCollection",
      "calculateDeadlines",
      "editOrder",
      "apiOrderIdOrderRowsPatch",
      "saveAndReleaseOrder",
      "apiOrderIdOrderRowsGet",
      "apiHistoricalOrderIdOrderRowsGet",
      "apiOrderIdChangeStatusToPatch",
      "apiOrderIdGet",
      "apiOrderIdApplyTaxesPost",
      "apiHistoricalOrderIdGet",
      "apiCutOrderFromOrderRows",
      "apiOrderIdRecalculateTotalsPost",
      "apiOrderMassiveDuplicate"
    ]),
    ...call("commercialSpecialists", ["initCommercialSpecialistCollection"]),
    ...call("currencies", ["initCurrenciesCollection"]),
    ...call("wormhole", ["apiOrderSyncIdPost"]),
    ...call("itemTranslation/*"),
    ...call("utils/*"),
    ...call("vats/*"),
  },
  watch: {
    internalOrderDate: function (val) {
      this.order.orderDate = this.$moment.utc(val).local();
    },
    internalEstimatedDeliveryDate: function (val) {
      this.order.estimatedDeliveryDate = this.$moment.utc(val).local().format('YYYY-MM-DD');
    },
  },
  computed: {
    orderRowsOfficial: sync('orders/orderRows'),
    customers: get("customers/allCustomers"),
    commercialSpecialists: get("commercialSpecialists/commercialSpecialists"),
    orders: get("orders/orders"),
    modalService: sync("modalService"),
    agentsDropdown: get("agents/dropdownActiveAgents"),
    agents: get("agents/agents"),
    families: get("families/families"),
    familiesDropdown: get("families/dropdownFamilies"),
    customersDropdown: get("orders/dropdownCustomersForNewOrder"),
    priceCatalogDropdown: get("priceCatalogs/dropdownPriceCatalogs"),
    priceCatalogs: get("priceCatalogs/priceCatalogs"),
    orderTypesDropdown: get("orders/dropdownOrderTypes"),
    orderTypes: get("orders/orderTypes"),
    vatPercentagesDropdown: get("vats/dropdownVatPercentages"),
    itemPriceList: sync("priceCatalogs/itemPriceList"),
    paymentTermsDropdown: get("orders/dropdownPaymentTerms"),
    paymentTerms: get("orders/paymentTerms"),
    paymentMethodsDropdown: get("orders/dropdownPaymentMethods"),
    paymentMethods: get("orders/paymentMethods"),
    orderStatus: get("orders/orderStatus"),
    items: get("items/items"),
    vatRates: get("vats/vatPercentages"),
    showWarmholeFab: get("tenantConfiguration/showWarmholeFab"),
    applyTaxesFromTaxJar: get("tenantConfiguration/applyTaxesFromTaxJar"),
    imSuperUserPremiumPlus: sync('orders/imSuperUserPremiumPlus'),
    languagesDropdown: get('itemTranslation/languagesDropdown'),
    getDescriptionByCode: get("items/getDescriptionByCode"),
    getItemByCode: get("items/getItemByCode"),
    itemPrices: get('priceCatalogs/itemPrices'),
    vatPercentageById: get("vats/vatPercentageById"),
    currencySymbol: get("tenantConfiguration/currencySymbol"),
    readonlyRowsColumns: function () {
      return [
        { value: 'rowNumber', text: this.$t('nr') },
        { value: 'itemCode', text: this.$t('item') },
        { value: 'itemDescription', text: this.$t('description') },
        { value: 'quantity', text: this.$t('quantity') },
        { value: this.getOriginalPrice(), text: this.$t('price'), align: 'right' },
        { value: 'rowDiscountPercentage', text: '+/- %', align: 'right' },
        { value: 'vatPercentageId', text: this.$t('taxes'), align: 'right' },
        { value: 'isGift', text: this.$t('gift') },
        { value: 'isLoanForUse', text: this.$t('lfu') },
        { value: 'notes', text: this.$t('notes') },
        { value: this.getAmountAfterDiscount(), text: this.$t('rowTotal'), align: 'right' },
      ]
    },
    separators(){
      return [
        { value: ';', text: this.$t('semicolon') },
        { value: ',', text: this.$t('comma') },
        { value: '|', text: this.$t('pipe') },
        { value: '&', text: this.$t('ampersand') }
      ]
    },
    selectionRules(){
      return [(v) => !!v || this.$t('required', {param:this.$t('selection')})]
    },
    headersCut(){
      return [
        { text: this.$t('item'), value: 'itemCode' },
        { text: this.$t('description'), value: 'itemDescription' },
        { text: this.$t('qty'), value: 'quantity' },
        { text: '', value: 'copy', align: 'end' },
        { text: this.$t('movingQty'), value: 'qty' },
      ]
    },
    poRules(){
      return [
        (v) => (v || "").length <= 50 || this.$t('lessThan', {param: this.$t('po'), qty: v.length}),
      ]
    },
    noteRules(){
      return [
        (v) => (v || "").length <= 255 || this.$t('lessThan', {param: this.$t('notes'), qty: v.length}),
      ]
    },
    collectionRules(){
      return [
        (v) => v === +v || "Collection Exp. should be a number",
        (v) => v >= 0 || "Collection Exp. should be equal or more than 0",
      ]
    },
    deadlines(){
      return {
        search: "",
        headers: [
          { text: this.$t('line'), align: "start", value: "line", },
          { text: this.$t('expirationDate'), value: "expirationDate", },
          { text: this.$t('amount'), value: "amount", align: 'right' },
        ],
        collection: [
          {
            line: "",
            expirationDate: "",
            amount: "",
          },
        ],
      }
    },
    rules(){
      return [
        (value) => !!value || this.$t('required', {param:''}),
        (value) => (value && value.length >= 3) || this.$t('minParam', {param:'3 char'}),
      ]
    },
    // currencies: get("currencies/currencies"),
    orderDateFormatted: function () {
      return `${this.$options.filters.formatDate(this.$moment.utc(this.getOrder().orderDate))}`;
    },
    estimatedDeliveryDateFormatted: function () {
      return `${this.$options.filters.formatDate(this.$moment.utc(this.getOrder().estimatedDeliveryDate))}`;
    },
    // orderRows: sync("orders/orderRows"),
    paymentTermDeadlinesTable: get("orders/paymentTermDeadlinesTable"),
    invoicingCustomerLocationsDropdown: function () {
      return this.invoicingCustomerLocations.map(x => ({ text: `${x.name} - ${x.address}`, value: x.id }));
    },
    shippingCustomerLocationsDropdown: function () {
      return this.shippingCustomerLocations.map(x => ({ text: `${x.name} - ${x.address}`, value: x.id }));
    },
    totalQuantity: function () {
      return (this.orderRows || []).reduce((a, b) => {
        a = a || 0;
        a += b.quantity || 0;
        return a;
      }, 0) || 0;
    },
    showTotalError: function () {
      return (this.order.orderTypeId === 6 && (this.order.documentCurTotal > 0 || (!!(this.orderRows.find(el => (el.documentCurRowAmountBeforeDiscount > 0)))))) || (this.order.orderTypeId !== 6 && this.order.documentCurTotal < 0)
    },
  },
};
</script>

<style scoped>
.resume {
  border-radius: 8px;
  border: 1px solid #3e5565;
}

.v-tab--active {
  background-color: #3e5565;
  color: white;
}

.custom-border {
  border: #3e5565 solid 1px;
  border-radius: 3px;
}

.inf-font {
  font-size: 18px;
  color: black;
  font-weight: 400;
}

.customer-header {
  font-size: x-large;
  font-weight: 500;
}

.align-right {
  text-align: end;
}

.bold {
  text-transform: uppercase;
  font-weight: 600;
}

.vh60-scrollable {
  height: 60vh;
  overflow-y: scroll;
}

.castelletto {
  border: 1px solid #ececec;
}

.castelletto-top {
  border-top: 2px solid #ececec;
}

.castelletto-bottom {
  border-bottom: 2px solid #ececec;
}

.castelletto-left {
  border-left: 2px solid #ececec;
}

.castelletto-right {
  border-right: 2px solid #ececec;
}

.mini-label-castelletto {
  text-transform: capitalize;
  font-size: smaller;
  font-weight: 600;
}
</style>
